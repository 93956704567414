<template>
  <div id="RobotManagement">
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
              type="button"
              class="btn btn-primary sticky-right"
              @click="showAddRobotDialog"
            >
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加机器人
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>添加时间</th>
            <th>机器人名称</th>
            <th>实时画面</th>
            <th>机器人ip</th>
            <th>摄像头ip</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="item in robotList" :key="item.id">
            <td style="min-width: 130px">{{ item.createTime }}</td>
            <td>{{ item.robotName }}</td>
            <td>
              <span class="allow-click" @click="showRealTimePictureDialog(item)"
                >查看</span
              >
            </td>
            <td>{{ item.robotIp }}</td>
            <td>{{ item.robotCameraIp }}</td>
            <td>
              <div class="btn-group">
                <button
                  type="button"
                  class="btn btn-primary dropdown-toggle"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="min-width: 76px; width: 100px">
                  <li>
                    <a style="width: 100%" @click="editRobot(item.id)"
                      >修改机器人</a
                    >
                  </li>
                  <li>
                    <a
                      style="width: 100%"
                      @click="
                        deleteRobotVisible = true;
                        robotId = item.id;
                      "
                      >删除机器人</a
                    >
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <!-- //分页 -->
      <Pagination name="pagination" componentName="Pagination"></Pagination>
      <CSDialog
        dialog-width="690px"
        :dialog-title="addRobotDialogTitle"
        :dialog-visible="addRobotVisible"
        @onConfirm="judgeAddOrEdit == 1 ? addRobotDevice() : editRobotDevice()"
        @onClose="addRobotVisible = false"
      >
        <template v-slot:dialog-content>
          <div style="padding: 30px">
            <div class="item">
              <div class="notice_title">机器人名称</div>
              <input
                v-model="addRobotInfo.robotName"
                type="text"
                class="hy-input"
                placeholder="限10个字"
                maxlength="10"
              />
            </div>
            <div class="item">
              <div class="notice_title">机器人ip</div>
              <input
                v-model="addRobotInfo.robotIp"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">用户名</div>
              <input
                v-model="addRobotInfo.robotUsername"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">密码</div>
              <input
                v-model="addRobotInfo.robotPassword"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">摄像头SN</div>
              <input
                v-model="addRobotInfo.robotCameraSn"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">摄像头ip</div>
              <input
                v-model="addRobotInfo.robotCameraIp"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">RTSP端口</div>
              <input
                v-model="addRobotInfo.robotCameraRtsp"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">摄像头用户名</div>
              <input
                v-model="addRobotInfo.robotCameraUsername"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">摄像头密码</div>
              <input
                v-model="addRobotInfo.robotCameraPassword"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>

            <div class="item">
              <div class="notice_title">pushStream</div>
              <input
                v-model="addRobotInfo.pushStreamAddr"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">rtmpPull</div>
              <input
                v-model="addRobotInfo.rtmpPullStreamAddr"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">rtsPull</div>
              <input
                v-model="addRobotInfo.rtsPullStreamAddr"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">m3u8Pull</div>
              <input
                v-model="addRobotInfo.m3u8PullStreamAddr"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
            <div class="item">
              <div class="notice_title">flvPull</div>
              <input
                v-model="addRobotInfo.flvPullStreamAddr"
                type="text"
                class="hy-input"
                placeholder="请输入"
              />
            </div>
          </div>
        </template>
      </CSDialog>
      <CSDialog
        dialog-width="1020px"
        dialog-title="查看实时画面"
        :dialog-visible="realTimePictureVisible"
        :dialog-show-confirm-btn="false"
        dialog-cancel-btn-text="关闭"
        @onClose="closePic"
        dialogHeaderClass="alert-bg"
      >
        <template v-slot:dialog-content>
          <div style="padding: 30px">
            <div class="viodeArea">
              <div class="topNav">
                <ul style="padding-left: 10px;text-align: right;">
                  <li>
                    机器人名称：{{ selectedData ? selectedData.robotName : "" }}
                  </li>
                </ul>
              </div>
              <div id="pictureVido"></div>
              <div class="bottomNav">
                <svg
                  class="icon iconSize"
                  aria-hidden="true"
                  @click="fullScreen"
                  style="opacity: 1"
                >
                  <use
                    :xlink:href="
                      isFullscreen
                        ? '#icon-menua-jianqu1'
                        : '#icon-menua-jianqu1'
                    "
                  ></use>
                </svg>
              </div>
            </div>
          </div>
        </template>
      </CSDialog>
      <CSDialog
        dialog-width="540px"
        dialog-header-class=" "
        :dialog-visible="deleteRobotVisible"
        dialog-confirm-btn-text="确定"
        dialog-header-icon="icon-menua-zu13"
        @onConfirm="deleteRobot"
        @onClose="deleteRobotVisible = false"
      >
        <template v-slot:dialog-content>
          <div style="padding: 30px; font-size: 24px; text-align: center">
            确定删除吗？
          </div>
        </template>
      </CSDialog>
    </div>
  </div>
</template>

<script>
import CSTable from "@/components/common/CSTable";
import CSDialog from "@/components/common/CSDialog";
import Pagination from "@/components/Pagination";
import {
  addRobotDeviceUrl,
  deleteRoboDeviceByIdUrl,
  queryRobotDeviceUrl,
  queryRobotDeviceByIdUrl,
  modifyRobotDeviceByIdUrl,
} from "@/requestUrl";
//判断浏览器类型
const isEdge = window.navigator.userAgent.toLocaleUpperCase().includes("EDG");
export default {
  name: "RobotManagement",
  components: {
    CSTable,
    CSDialog,
    Pagination,
  },
  data() {
    return {
      isFullscreen: false,
      player: null,
      selectedData: null,
      filterHeight: "",
      addRobotDialogTitle: "添加机器人",
      addRobotVisible: false,
      realTimePictureVisible: false,
      deleteRobotVisible: false,
      regionCode: this.$vc.getCurrentRegion().code,
      robotList: [],
      addRobotInfo: {
        robotName: "",
        robotIp: "",
        robotUsername: "",
        robotPassword: "",
        robotCameraIp: "",
        robotCameraRtsp: "",
        robotCameraUsername: "",
        robotCameraPassword: "",
        robotCameraSn: "",
        pushStreamAddr: "",
        rtmpPullStreamAddr: "",
        rtsPullStreamAddr: "",
        m3u8PullStreamAddr: "",
        flvPullStreamAddr: "",
      },
      robotStateObj: {
        0: "空闲",
        1: "运行中",
        2: "充电中",
        3: "退出充电",
        4: "重新定位",
        5: "系统错误",
        6: "地盘错误",
        7: "传感错误",
      },
      robotId: "",
      judgeAddOrEdit: null,
    };
  },
  created() {
    // 查询机器人列表
    this.queryRobotDeviceList();
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel")?.offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel")?.offsetHeight;
    });
  },
  methods: {
    //全屏
    fullScreen() {
      const fullViewEl = document.querySelector(".viodeArea");
      fullViewEl.requestFullscreen();
      this.isFullscreen = true;
    },
    closePic() {
      this.realTimePictureVisible = false;
    },
    //查询机器人拉流
    async queryRotorStr(item) {
      // if(item.state == 0){
      //     return
      // }
      const { rtsPullStreamAddr: liveUrl, flvPullStreamAddr: flvUrl } = item;
      //阿里云播放器
      this.player = new Aliplayer(
        {
          id: `pictureVido`,
          source: isEdge ? flvUrl : liveUrl,
          width: "960px",
          height: "540px",
          autoplay: true,
          isLive: true,
          rePlay: false,
          playsinline: true,
          preload: true,
          controlBarVisibility: "never",
          useH5Prism: true,
          skinLayout: [{ name: "bigPlayButton", align: "cc" }],
        },
        function (player) {
          //player.play();
          if (player) {
            player.setVolume(0);
            console.log(
              player.getStatus(),
              "播放状态",
              "视频音量" + player.getVolume()
            );
          }
        }
      );

      return;
    },
    // 添加机器人
    async addRobotDevice() {
      console.log(this.addRobotInfo);
      for(let key in this.addRobotInfo){
        if(this.addRobotInfo[key] == ""){
          this.$vc.message('请完整填写');
          return;
        }
      }
      const res = await this.$fly.post(addRobotDeviceUrl, {
        ...this.addRobotInfo,
        regionCode: this.regionCode,
      });
      if (res.code != 0) {
        return;
      }
      this.$vc.toast("添加成功");
      await this.queryRobotDeviceList();
      this.addRobotVisible = false;
    },
    // 修改机器人
    async editRobotDevice() {
      for(let key in this.addRobotInfo){
        if(this.addRobotInfo[key] == ""){
          this.$vc.message('请完整填写');
          return;
        }
      }
      const res = await this.$fly.put(
        `${modifyRobotDeviceByIdUrl}/${this.robotId}`,
        {
          ...this.addRobotInfo,
          regionCode: this.regionCode,
        }
      );
      if (res.code != 0) {
        return;
        ``````````````;
      }
      this.addRobotVisible = false;
      await this.queryRobotDeviceList();
      this.$vc.toast("修改成功");
    },
    // 删除机器人
    async deleteRobot() {
      const res = await this.$fly.delete(
        `${deleteRoboDeviceByIdUrl}/${this.robotId}`
      );
      if (res.code != 0) {
        this.$vc.toast("删除失败");
        return;
      }
      this.$vc.toast("删除成功");
      await this.queryRobotDeviceList();
      this.deleteRobotVisible = false;
    },
    // 显示添加机器人弹窗
    showAddRobotDialog() {
      (this.addRobotInfo = {
        robotName: "",
        robotIp: "",
        robotUsername: "",
        robotPassword: "",
        robotCameraIp: "",
        robotCameraRtsp: "",
        robotCameraUsername: "",
        robotCameraPassword: "",
        pushStreamAddr: "",
        rtmpPullStreamAddr: "",
        rtsPullStreamAddr: "",
        m3u8PullStreamAddr: "",
        flvPullStreamAddr: "",
      }),
        (this.addRobotDialogTitle = "添加机器人");
      this.judgeAddOrEdit = 1;
      this.addRobotVisible = true;
    },
    // 修改机器人
    async editRobot(id) {
      this.addRobotDialogTitle = "修改机器人";
      this.judgeAddOrEdit = 2;
      this.robotId = id;
      await this.queryRobot(id);
      this.addRobotVisible = true;
    },
    // 显示实时画面
    showRealTimePictureDialog(item) {
      this.selectedData = item;
      console.log(item);
      this.realTimePictureVisible = true;
      setTimeout(() => {
        this.queryRotorStr(this.selectedData);
      }, 20);
    },
    // 查询单个机器人
    async queryRobot(id) {
      const res = await this.$fly.get(`${queryRobotDeviceByIdUrl}/${id}`);
      if (res.code != 0) {
        return;
      }
      console.log(res.data);
      this.addRobotInfo = {
        robotName: res.data.robotName,
        robotIp: res.data.robotIp,
        robotUsername: res.data.robotUsername,
        robotPassword: res.data.robotPassword,
        robotCameraIp: res.data.robotCameraIp,
        robotCameraRtsp: res.data.robotCameraRtsp,
        robotCameraUsername: res.data.robotCameraUsername,
        robotCameraPassword: res.data.robotCameraPassword,
        robotCameraSn: res.data.robotCameraSn,
        pushStreamAddr: res.data.pushStreamAddr,
        rtmpPullStreamAddr: res.data.rtmpPullStreamAddr,
        rtsPullStreamAddr: res.data.rtsPullStreamAddr,
        m3u8PullStreamAddr: res.data.m3u8PullStreamAddr,
        flvPullStreamAddr: res.data.flvPullStreamAddr,
      };
    },
    // 查询机器人列表
    async queryRobotDeviceList() {
      const res = await this.$fly.get(
        `${queryRobotDeviceUrl}/${this.regionCode}`
      );
      if (res.code != 0) {
        return;
      }
      this.robotList = res.data;
      console.log(this.robotList);
    },
  },
};
</script>

<style lang="stylus" scoped>
.item {
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }

  .notice_title {
    display: inline-block;
    width: 146px;
    font-size: 24px;
    color: #000;
    text-align: right;
    margin-right: 40px;
    vertical-align: middle;
  }
}

.viodeArea {
  position: relative;
  width: 960px;
  height: 540px;
  overflow: hidden;
}

.viodeArea:hover .bottomNav {
  transform: translateY(0) !important;
}

.pictureVido {
  position: absolute;
  left: 0;
  top: 0;
  width: 960px;
  height: 540px;
}

.topNav {
  width: 100%;
  height: 50px;
  background: rgba(0, 0, 0, 0.39);
  opacity: 1;
  position: absolute;
  z-index: 2;
}

.topNav ul li {
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 30px;
  color: #FFFFFF;
  opacity: 1;
  display: inline-block;
  margin-right: 10px;
  line-height: 50px;
}

.bottomNav {
  width: 100%;
  height: 50px;
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.39);
  transition: all 1s;
  transform: translateY(50px);
}

.iconSize {
  font-size: 30px;
  float: right;
  margin-top: 10px;
  margin-right: 20px;
}
</style>
